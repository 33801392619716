const addInfoBtn = document.querySelector('#addInfoBtn')
const addInfoContainer = document.querySelector('#additionalInfo')
const submitButton = document.querySelector('#submitBtn')

addInfoBtn.addEventListener('click', () => {
	addInfoContainer.classList.toggle('hidden')
})

// Validation
const inputFile = document.querySelector('#dropzone-file')

const checkAttachment = input => {
	if (input.files.length >= 1) {
		input.classList.remove('hidden')
	} else {
		input.classList.add('hidden')
	}
}

const checkRequiredFields = e => {
	const requiredFields = document.querySelectorAll('.required')
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	const phoneNumberRegex = /^[0-9+()\s-]+$/
	const loader = document.querySelector('.loader')
	let isCorrect = true
	requiredFields.forEach(input => {
		const errInfo = input.parentElement.querySelector('p')
		errInfo.style.color = 'red'
		errInfo.classList.add('text-center')
		errInfo.innerHTML = ''
		if (!input.value.trim() && input.type !== 'checkbox') {
			e.preventDefault()
			errInfo.innerHTML = 'Uzupełnij wymagane pole'
			isCorrect = false
		}

		if (input.value.trim() && input.type === 'email') {
			if (!emailRegex.test(input.value)) {
				e.preventDefault()
				errInfo.innerHTML = 'Podaj poprawny adres e-mail'
				isCorrect = false
			}
		}

		if (input.value.trim() && input.type === 'tel') {
			if (!phoneNumberRegex.test(input.value)) {
				e.preventDefault()
				errInfo.innerHTML = 'Podaj poprawny numer telefonu'
				isCorrect = false
			}
		}

		if (input.type === 'file') {
			if (input.files.length === 0) {
				e.preventDefault()
				errInfo.innerHTML = 'Dodaj załącznik z CV'
				isCorrect = false
			} else if (input.files[0].size > 5242880) {
				console.log(input)
				e.preventDefault()
				errInfo.innerHTML = 'Rozmiar pliku nie powinien przekraczać 5 MB'
				isCorrect = false
			}
		}

		if (input.type === 'checkbox' && !input.checked) {
			e.preventDefault()
			errInfo.innerHTML = 'Zaznacz wymagane pole'
			isCorrect = false
		}
	})

	isCorrect ? loader.classList.remove('hidden') : loader.classList.add('hidden')
}

submitButton.addEventListener('click', e => {
	checkRequiredFields(e)
})
checkAttachment(inputFile)
inputFile.addEventListener('input', e => {
	checkAttachment(e.target)
})
